<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-04-09 11:57:13
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-08 18:31:13
-->
<template>
  <div class="choose_rebate">
    <a-modal title="选择折扣" v-model="visible" width="70%" @ok="onSubmit" okText="确定" :maskClosable="false">
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="可用折扣">
          <a-checkbox-group
            v-if="rowData.canUsedList != null && rowData.canUsedList.length > 0"
            v-model="checkboxIds"
            @change="onChange"
          >
            <a-checkbox
              style="margin-bottom: 10px"
              v-for="(item, index) in rowData.canUsedList"
              :key="index"
              :value="item.id"
            >
              <div class="checked_inline">
                <span class="checkbox_text">({{ item.rebateName }}) 折扣金额：{{ item.rebateAmount }}</span>
                <span class="checkbox_text">适用范围：{{ item.categoryNameStr }}</span>
                <span>折扣有效期：{{ item.startTime }} - {{ item.endTime }}</span>
              </div>
            </a-checkbox>
          </a-checkbox-group>
          <div v-else>暂无可使用折扣</div>
        </a-form-model-item>
        <a-form-model-item
          label="不可用折扣"
          v-if="rowData.canNotUsedList != null && rowData.canNotUsedList.length > 0"
        >
          <a-checkbox-group v-model="checkboxIds">
            <a-checkbox
              style="margin-bottom: 10px"
              disabled
              v-for="(item, index) in rowData.canNotUsedList"
              :key="index"
              :value="item.id"
            >
              <div class="checked_inline">
                <span class="checkbox_text">({{ item.rebateName }}) 折扣金额：{{ item.rebateAmount }}</span>
                <!--               <span class="checkbox_text">折扣名称：{{item.rebateName}}</span>-->
                <span class="checkbox_text">适用范围：{{ item.categoryNameStr }}</span>
                <span>折扣有效期：{{ item.startTime }} - {{ item.endTime }}</span>
              </div>
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { formatMoneyOfRound } from '../../../utils/util.js'

export default {
  data() {
    return {
      visible: false,
      rowData: {},
      checkboxIds: [],
      categoryId: '',
      canUsedList: [],
      datalist: [],
      checkedRow: [],
      productList: [],
      dealerId: '',
      orderInfoDetailGoodsVOS: [],
    }
  },
  created() {},
  methods: {
    isShow(dealerId, orderCode, checkedRebateList, productList) {
      const arr = new Map()
      this.visible = true
      this.dealerId = dealerId
      this.orderCode = orderCode
      this.checkboxIds = checkedRebateList // 默认选中
      console.log("this.checkboxIds",this.checkboxIds)
      this.productList = productList // 商品信息
      let ids = this.productList.map((e) => e.categoryId)
      ids = ids.filter((e) => !arr.has(e) && arr.set(e, 1))
      let obj = {
        dealerId,
        cateIds: ids,
        orderCode: orderCode,
      }
      this.axios
        .post(`/api/dealer/dealer/dealerRebate/getDealerRebateListVO`, obj, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          // 获取折扣列表
          this.rowData = res.body

          this.initOldSelect()
        })
    },

    initOldSelect() {
      const reqData = {
        dealerId: this.dealerId,
        orderInfoDetailDTOList: this.productList,
        usedRebateIds: this.checkboxIds,
        orderCode: this.orderCode,
      }

      this.axios.post(`/api/order/order/orderInfo/getNewRebatePriceByUsedRebate`, reqData).then((res) => {
        if (res.code == 200) {
          //this.checkboxIds = res.body.rebateVOList.map(e => e.id)
          this.orderInfoDetailGoodsVOS = res.body.orderInfoDetailGoodsVOS
        }
      })
    },

    onChange(val) {
      // 选中折扣不按照点击顺序，默认后端返回顺序
      let tmpList = this.rowData.canUsedList.filter((e) => {
        return val.indexOf(e.id) != -1
      })
      this.checkboxIds = tmpList.map((e) => e.id)

      let reqData = {
        dealerId: this.dealerId,
        orderInfoDetailDTOList: this.productList,
        usedRebateIds: this.checkboxIds,
        orderCode: this.orderCode,
      }

      this.axios
        .post(`/api/order/order/orderInfo/getNewRebatePriceByUsedRebate`, reqData)
        .then((res) => {
          if (res.code == 200) {
            if (res.body.rebateVOList != null) {
              this.checkboxIds = res.body.rebateVOList.map((e) => e.id)
            }
            this.orderInfoDetailGoodsVOS = res.body.orderInfoDetailGoodsVOS
          }
        })
        .catch((error) => {
          // 如果接口返回500那么删除最后一个选中
          let end = val.pop()
          this.checkboxIds = val
        })
    },
    onSubmit() {
      this.$emit('checkout', this.checkboxIds, this.orderInfoDetailGoodsVOS)
      this.visible = false
      this.checkboxIds = []
    },
  },
}
</script>

<style scoped>
.checked_inline {
  display: inline-block;
  width: 95%;
}
.ant-form-item label,
.ant-checkbox-group {
  width: 100%;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.checkbox_text {
  display: inline-block;
  width: 33%;
}
</style>