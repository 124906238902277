<template>
  <div class="check-order-info">
    <a-modal v-model="visible" title="折扣详情" width="60%" style="top: 8px" :footer="null" :maskClosable="false">
      <a-spin :spinning="spinning">
      <a-tabs v-model="checkedTabs">
        <a-tab-pane key="base_info" tab="基本信息">
          <a-descriptions bordered>
            <a-descriptions-item label="折扣名称">
              {{rowData.rebateName}}
            </a-descriptions-item>
            <a-descriptions-item label="折扣金额">
              {{rowData.rebateAmount | formatMoney }}
            </a-descriptions-item>
            <a-descriptions-item label="经销商">
              {{ rowData.dealerName }}
            </a-descriptions-item>
            <a-descriptions-item label="大区">
              {{ rowData.mhiacAreaName }}
            </a-descriptions-item>
            <a-descriptions-item label="事务所" :span="2">
              {{ rowData.firmName }}
            </a-descriptions-item>
            <a-descriptions-item label="优惠费用类型">
             {{ rowData.costType == 1 ? '返利':'推广费'}}
            </a-descriptions-item>
            <a-descriptions-item label="有效期" :span="2">
              {{ rowData.startTime }} ~ {{ rowData.endTime}}
            </a-descriptions-item>

            <a-descriptions-item label="备注" :span="3">
              {{ rowData.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="适用范围" :span="3">
              <a-tag class="cate-tag"  v-for="(item, index) in rowData.categoryNames" :key="index">{{ item }}</a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="附件" :span="3">
              <QiniuUpload
                :disabled="true"
                type="file"
                :value.sync="filesUrl"
                :size="5"
              ></QiniuUpload>
            </a-descriptions-item>
          </a-descriptions>
        </a-tab-pane>
        <a-tab-pane key="used_order_detail" tab="使用记录">
           <UsedOrderDetail :rebateId="rebateId" ref="UsedOrderDetail"></UsedOrderDetail>
        </a-tab-pane>
<!--        <a-tab-pane key="ship_info" tab="发货记录" >-->

<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="operating_info" tab="操作日志">-->

<!--        </a-tab-pane>-->
      </a-tabs>

      <div class="footer-bts">
        <a-button type="default" @click="visible = false">关闭</a-button>
      </div>
      </a-spin>
    </a-modal>

  </div>
</template>

<script>

import { selectByIdDealerRebate } from '../api/DealerRebateApi'

import UsedOrderDetail from '@/views/dealer_rebate/components/UsedOrderDetail.vue'

export default {
  name: '',
  data() {
    return {
      visible: false,
      rowData: [],
      checkedTabs: 'base_info',
      loading:false,
      root:'',
      spinning:false,
      rebateId:'',
      filesUrl:''
    }
  },

  components: {
    UsedOrderDetail
  },

  computed: {},

  created() {},

  filters: {

  },

  methods: {
    isShow(row) {
      this.spinning = true
      let timerId = setInterval(() => {
        clearInterval(timerId)
        this.visible = true
      }, 500)
      this.rebateId = row.id
      this.checkedTabs = 'base_info'

      selectByIdDealerRebate(this.rebateId).then((res) => {
        this.rowData = res.body
        this.filesUrl = res.body.files
          ? res.body.files
            .map((e) => {
              return e.fileUrl
            })
            .toString()
          : ''
        let ids = res.body.categoryIdStr.split(',').map(Number)
        this.rowData.categoryIds = ids
        this.spinning = false
      })

    },


  },
}
</script>

<style lang='scss' scoped>

.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}

.cate-tag{
  margin-bottom: 5px;
}
</style>